import React from "react"
import { graphql } from "gatsby"

import CursorProvider from "context/cursor"

import Layout from "components/Layout"
import {
  Hero,
  Discover,
  // Apartments,
  Entertainment,
  Residence,
  Baner,
  Gallery,
  Location,
  History,
  About,
  Contact,
  CallToAction,
} from "page_components/hugo-landing"

import { Apartments } from "page_components/investment"

const Investment = ({ data, location }) => {
  const investment = "Browar Hugo Scobel"
  const investmentRaw = "hugo_scobel"
  const allApartments = data.allWpApartmentHugoScobel.nodes

  return (
    <CursorProvider variant="hugo">
      <Layout
        location={location}
        rmGlobalPopup
        mainClass="hugo-landing"
        seo={{
          title: investment,
        }}
      >
        <Hero />
        <Discover />
        <Apartments
          apartmentList={allApartments}
          investment={investment}
          investmentRaw={investmentRaw}
        />
        <Entertainment />
        <Residence />
        <Baner />
        <Gallery />
        <Location />
        <History />
        <About />
        <Contact />
        <CallToAction />
      </Layout>
    </CursorProvider>
  )
}

export const query = graphql`
  query {
    allWpApartmentHugoScobel(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          d3EstateUrl
          type
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
          placesList {
            area
            name
          }
        }
      }
    }
  }
`

export default Investment
